<template>
  <div></div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    created() {
        this.downApp()
    },
    mounted() {

    },
    methods: {
        downApp(){
            var u = navigator.userAgent, 
            isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1,
            isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/),
            urls = {
                'android':'https://play.google.com/store/apps/details?id=com.holowits.holo',
                'ios':'https://apps.apple.com/us/app/holowits/id1619309632',
                'other':''
            };          

            //跳转
            if(isAndroid){
                // console.log(1)
                window.location.href=urls.android;
            }else if(isiOS){
                // console.log(2)
                window.location.href=urls.ios;
            }
            // else{
            //     window.location.href=urls.other;
            // }
        }
    }
}
</script>

<style>

</style>